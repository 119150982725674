.App {
  text-align: center;
  height: 100%;
}
.header-navbar {
  border-bottom: 5px solid #cccccc;
  height: 140px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.left-navbar {
  width: 250px;
  margin-right: 10px;
  border-right: 1px solid #cccccc;
}

.main-content {
  width: 100%;
}

.settings-container {
  width: 100%;
  text-align: left;
  padding-right: 10px;
}

.custom-file-input {
  font-size: 0.5rem;
  padding: 5px 8px;
  text-align: center;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Vyberte obrázek";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  width: 100%;
  border-radius: 3px;
  text-align: center;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.custom-font-input {
  font-size: 0.5rem;
  padding: 5px 8px;
  text-align: center;
}
.custom-font-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-font-input::before {
  content: "Vyberte font";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  width: 100%;
  border-radius: 3px;
  text-align: center;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-font-input:hover::before {
  border-color: black;
}
.custom-font-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
