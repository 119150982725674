.image-border {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid red;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.image-container {
  position: relative;
  width: 100%;
  padding-right: 10px;
}

.paddings {
  width: 50px;
}

.column-input {
  width: 80px;
  text-align: center;
}

.image-column {
  position: absolute;
  border: 1px dotted green;
}

.column-header {
  margin-bottom: 0;
  line-height: 1;
}

.column-text {
  margin-bottom: 0;
  line-height: 1;
}

.image-special-element {
  position: absolute;
  border: 1px dotted green;
}

.image-draggable-container {
  border: 1px dashed red;
}
.image-draggable-text {
  margin-bottom: 0;
  line-height: 1;
}
